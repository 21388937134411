html {
  height: 100%;
}

:root {
  --scrollbar-track-color: #fff;
  --scrollbar-thumb-color: #8e9ea0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: rgb(255, 255, 255);
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}
input[type='text']::-ms-clear {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ============================================================  Global scroll-bar style start*/

::-webkit-scrollbar {
  width: 0.6vw;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 2vw;
}

::-webkit-scrollbar-thumb:hover {
  border: 0.1em solid var(--scrollbar-thumb-color);
}

/* ============================================================  Global scroll-bar style End*/

/* ============================================================  side-menu scroll-bar style start*/
.side-menu-scrollbar::-webkit-scrollbar {
  width: 0.3vw;
}

.side-menu-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.side-menu-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 2vw;
}

.side-menu-scrollbar {
  scrollbar-color: var(--scrollbar-thumb-color) transparent; /* this is for mozilla browser */
  scrollbar-width: thin; /* this is for mozilla browser */
}
/* ============================================================  side-menu scroll-bar style end*/
